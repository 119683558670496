.icon-arrow-right {
  margin-left: 10px;
  font-size: 10px;
  display: inline-block;
  transition: 0.5s ease;
}

// primary button
.primary-btn {
  position: relative;
  padding: 1.5rem 2.5rem;
  font-size: 2.2rem;
  letter-spacing:1px;
  border: 1px solid var(--border-color);
  overflow: hidden;
  border-radius: 0;
  font-weight: 300;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: $red;
    transition: all 0.3s;
    z-index: -1;
  }

  &:hover {
    border: 1px solid $red;
    color: $white !important;
    &:before {
      width: 100%;
    }
  }
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  @include breakpoint(max-1680) {
    font-size: 1.9rem;
    padding: 1.3rem 2.1rem;
  }
  @include breakpoint(max-1440) {
    font-size: 1.6rem;
    padding: 1.1rem 1.8rem;
  }
  @include breakpoint(max-1366) {
    font-size: 1.5rem;
    padding: 1.1rem 1.7rem;
  }
  @include breakpoint(max-1280) {
    font-size: 1.4rem;
    padding: 1rem 1.6rem;
  }
  @include breakpoint(max-1024) {
    // font-size: 1.2rem;
    padding: 0.8rem 1.3rem;
  }
  &--nav{
    padding: 0.8rem 1.5rem;
    font-size: 1.15rem;
    font-weight: 300;
    border-radius: 1rem;
  }
}
.secondary-btn {
  padding: 1.8rem 4rem;
  color: $white !important;
  font-size: 1.9rem;
  letter-spacing:1px;
  font-weight: 300;

  background: $red;
  border: 0.1rem solid $red;
  transition: 0.5s ease;
  border-radius: 0;

  &:hover {
    background-color: $black;
    border: 0.1rem solid $white;

    .icon-arrow-right {
      transform: translateX(5px);
    }
  }
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
  @include breakpoint(max-1680) {
    font-size: 1.6rem;
    padding: 1.5rem 3.5rem;
  }
  @include breakpoint(max-1440) {
    font-size: 1.4rem;
    padding: 1.3rem 3rem;
  }
  @include breakpoint(max-1366) {
    font-size: 1.3rem;
    padding: 1.2rem 2.8rem;
  }
  @include breakpoint(max-1280) {
    font-size: 1.2rem;
    padding: 1.2rem 2.6rem;
  }
  @include breakpoint(max-1024) {
    font-size: 1.4rem;
    padding: 0.9rem 2.1rem;
  }
}
.back-btn {
  display: inline-flex !important;
  align-items: center;
  padding: 0.5rem 1.2rem;
  font-size: 1.5rem;
  border: 1px solid var(--border-color);
  opacity: 0.8;
  border-radius: 1rem;
  line-height: 1;
  font-weight: 300;
  .icon-angle-left {
    font-size: 1.5rem;
    transition: 0.3s all;
    display: inline-block;
    margin-right: 0.5rem;

    @include breakpoint(mobile-max) {
      margin-right: 0.4rem;
      font-size: 1.7rem;
    }
  }
  &:hover {
    .icon-angle-left {
      transform: translateX(-5px);
    }
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
  @include breakpoint(mobile-max) {
    padding: 0.5rem 1rem;
    font-size: 1.3rem;
  }
}

// mute
.mute_btn {
  position: absolute;
  display: flex !important;
  align-items: center;
  justify-content: center;
  right: 1rem;
  bottom: 1rem;
  background: #00000087;
  color: #fff;
  height: 4rem;
  cursor: pointer;
  line-height: 4rem;
  width: 4rem;
  border-radius: 50%;

  .hide {
    display: none;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}