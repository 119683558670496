/*!
* Project Name
* Architecture for a Sass Project
* https://www.sitepoint.com/architecture-sass-project/
* OR
* http://matthewelsom.com/blog/simple-scss-playbook.html
*/
:root {
  --bg-color: #ffffff;
  --border-color:rgba(129, 129, 129, 1);
  --card-border-color:rgba(0,0,0,0.55);
  --font-color: #000000;
  --color-red: #EB0C0C;
  --color-light-grey:#D9D9D9;
  --color-dark-grey:#1B1B1B;
  --header-bg:rgba(255,255,255,0.65);
  --toggler-color:#000000;
  --tabs-color:#D9D9D9;
  --loader-color:rgba(255,255,255,0.9);
  --arrow-right-color:#ffffff;
}

[data-theme=dark] {
  --bg-color: #000000;
  --border-color:rgba(255,255,255,0.5);
  --card-border-color:rgba(85, 85, 85, 1);
  --font-color: #ffffff;
  --color-red: #EB0C0C;
  --color-light-grey:#D9D9D9;
  --color-dark-grey:#1B1B1B;
  --header-bg:rgba(0, 0, 0, 0.55);
  --toggler-color:#ffffff;
  --tabs-color:#161616;
  --loader-color:rgba(0,0,0, 0.7);
  --arrow-right-color:#000000;
}

/* placeholder */
:root {
  --bg-color: #ffffff;
  --border-color:rgba(129, 129, 129, 1);
  --card-border-color:rgba(0,0,0,0.55);
  --font-color: #000000;
  --color-red: #EB0C0C;
  --color-light-grey:#D9D9D9;
  --color-dark-grey:#1B1B1B;
  --header-bg:rgba(255,255,255,0.65);
  --toggler-color:#000000;
  --tabs-color:#D9D9D9;
  --loader-color:rgba(255,255,255,0.9);
  --arrow-right-color:#ffffff;
}

[data-theme=dark] {
  --bg-color: #000000;
  --border-color:rgba(255,255,255,0.5);
  --card-border-color:rgba(85, 85, 85, 1);
  --font-color: #ffffff;
  --color-red: #EB0C0C;
  --color-light-grey:#D9D9D9;
  --color-dark-grey:#1B1B1B;
  --header-bg:rgba(0, 0, 0, 0.55);
  --toggler-color:#ffffff;
  --tabs-color:#161616;
  --loader-color:rgba(0,0,0, 0.7);
  --arrow-right-color:#000000;
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?hfqvtj");
  src: url("../fonts/icomoon.eot?hfqvtj#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?hfqvtj") format("truetype"), url("../fonts/icomoon.woff?hfqvtj") format("woff"), url("../fonts/icomoon.svg?hfqvtj#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-drupal:before {
  content: "\e902";
}

.icon-magento:before {
  content: "\e903";
}

.icon-php:before {
  content: "\e904";
}

.icon-arrow-right:before {
  content: "\e909";
}

.icon-angle-right:before {
  content: "\e900";
}

.icon-angle-left:before {
  content: "\e901";
}

.icon-wordpress:before {
  content: "\eab4";
}

@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Light.ttf") format("truetype"), url("../fonts/Poppins-Light.ttf") format("truetype") url("../fonts/Poppins-Light.woff2") format("woff2"), url("../fonts/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Regular.ttf") format("truetype"), url("../fonts/Poppins-Regular.ttf") format("truetype"), url("../fonts/Poppins-Regular.woff2") format("woff2"), url("../fonts/Poppins-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Medium.ttf") format("truetype"), url("../fonts/Poppins-Medium.ttf") format("truetype") url("../fonts/Poppins-Medium.woff2") format("woff2"), url("../fonts/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-SemiBold.ttf") format("truetype"), url("../fonts/Poppins-SemiBold.ttf") format("truetype") url("../fonts/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins-Bold.ttf") format("truetype"), url("../fonts/Poppins-Bold.ttf") format("truetype") url("../fonts/fonts/Poppins-Bold.woff2") format("woff2"), url("../fonts/fonts/Poppins-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
section {
  padding: 10rem 0;
}
@media (max-width: 1680px) {
  section {
    padding: 8.8rem 0;
  }
}
@media (max-width: 1440px) {
  section {
    padding: 7.5rem 0;
  }
}
@media (max-width: 1366px) {
  section {
    padding: 7.1rem 0;
  }
}
@media (max-width: 1280px) {
  section {
    padding: 6.6rem 0;
  }
}
@media (max-width: 1024px) {
  section {
    padding: 5.3rem 0;
  }
}
@media (max-width: 767px) {
  section {
    padding: 2rem 0;
  }
}

.contact-us {
  background: url("../images/contact-bg.jpg") no-repeat center center;
  background-size: cover;
  margin-top: 10rem;
}
.contact-us__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10rem 0;
}
.contact-us__info-title {
  color: #ffffff !important;
  margin-bottom: 3rem;
}
@media (max-width: 1680px) {
  .contact-us__info-title {
    margin-bottom: 2.6rem;
  }
}
@media (max-width: 1440px) {
  .contact-us__info-title {
    margin-bottom: 2.2rem;
  }
}
@media (max-width: 1366px) {
  .contact-us__info-title {
    margin-bottom: 2.1rem;
  }
}
@media (max-width: 1280px) {
  .contact-us__info-title {
    margin-bottom: 2rem;
  }
}
@media (max-width: 1024px) {
  .contact-us__info-title {
    margin-bottom: 1.6rem;
  }
}
@media (max-width: 1680px) {
  .contact-us__info {
    padding: 8.8rem 0;
  }
}
@media (max-width: 1440px) {
  .contact-us__info {
    padding: 7.5rem 0;
  }
}
@media (max-width: 1366px) {
  .contact-us__info {
    padding: 7.1rem 0;
  }
}
@media (max-width: 1280px) {
  .contact-us__info {
    padding: 6.6rem 0;
  }
}
@media (max-width: 1024px) {
  .contact-us__info {
    padding: 5.3rem 0;
  }
}
@media (max-width: 767px) {
  .contact-us__info {
    padding: 2rem 0;
  }
}
@media (max-width: 1680px) {
  .contact-us {
    margin-top: 8.8rem;
  }
}
@media (max-width: 1440px) {
  .contact-us {
    margin-top: 7.5rem;
  }
}
@media (max-width: 1366px) {
  .contact-us {
    margin-top: 7.1rem;
  }
}
@media (max-width: 1280px) {
  .contact-us {
    margin-top: 6.6rem;
  }
}
@media (max-width: 1024px) {
  .contact-us {
    margin-top: 5.3rem;
  }
}
@media (max-width: 767px) {
  .contact-us {
    margin-top: 2rem;
  }
}

.contact-index {
  margin-top: 0;
}

.stroke-text {
  color: #919191;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #919191;
}

#custom-cursor {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  pointer-events: none;
}

@media (hover: hover) and (pointer: fine) {
  .cursor__circle {
    width: 1rem;
    height: 1rem;
    margin-top: -50%;
    margin-left: -50%;
    border-radius: 50%;
    background-color: #000000;
    border: solid 1px rgba(227, 222, 193, 0.64);
    transition: opacity 0.9s cubic-bezier(0.25, 1, 0.5, 1), background-color 0.3s cubic-bezier(1, 1, 1, 1), border-color 0.3s cubic-bezier(0.25, 1, 0.5, 1), width 0.3s cubic-bezier(0.25, 1, 0.5, 1), height 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  }
  #custom-cursor::after {
    content: "View Project";
    position: absolute;
    left: -50%;
    top: -50%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 0;
    transition: 0.3s all;
  }
  #custom-cursor.arrow::after {
    font-size: 1.2rem;
  }
  #custom-cursor.arrow .cursor__circle {
    width: 10rem;
    height: 10rem;
    border-radius: 100%;
    background-color: #000000;
    border-color: #000000;
  }
}
#swip-cursor {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  pointer-events: none;
}

@media (hover: hover) and (pointer: fine) {
  .swip__circle {
    width: 1rem;
    height: 1rem;
    margin-top: -50%;
    margin-left: -50%;
    border-radius: 50%;
    background-color: #000000;
    border: solid 1px rgba(227, 222, 193, 0.64);
    transition: opacity 0.9s cubic-bezier(0.25, 1, 0.5, 1), background-color 0.3s cubic-bezier(1, 1, 1, 1), border-color 0.3s cubic-bezier(0.25, 1, 0.5, 1), width 0.3s cubic-bezier(0.25, 1, 0.5, 1), height 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  }
  #swip-cursor::after {
    content: "";
    position: absolute;
    left: -50%;
    top: -50%;
    width: 100%;
    height: 100%;
    background-image: url("../images/drag.svg");
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 0;
    transition: 0.3s all;
  }
  #swip-cursor.arrow::after {
    font-size: 2rem;
  }
  #swip-cursor.arrow .swip__circle {
    width: 10rem;
    height: 10rem;
    border-radius: 100%;
    background-color: #000000;
    border-color: #000000;
  }
}
.tooltip {
  font-size: 1.2rem;
}

html {
  font-size: 10px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body {
  font-size: 1rem;
  font-family: "Poppins";
  background-color: var(--bg-color);
  position: relative;
  color: var(--font-color);
  transition: 0.3s all;
}

/**
* Firefox specific rule
*/
@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}
* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  display: inline-block !important;
  color: inherit !important;
  text-decoration: none !important;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
}

li {
  list-style-type: none;
  display: inline-block;
}

img {
  margin: 0;
  max-width: 100%;
  height: auto;
  border: 0;
  display: inline-block;
}

button {
  outline: none;
  border: none;
}

.heading-1 {
  font-size: 8.2rem;
  letter-spacing: 1px;
  font-weight: 300;
}
@media (max-width: 1680px) {
  .heading-1 {
    font-size: 7.1rem;
  }
}
@media (max-width: 1440px) {
  .heading-1 {
    font-size: 6.1rem;
  }
}
@media (max-width: 1366px) {
  .heading-1 {
    font-size: 5.7rem;
  }
}
@media (max-width: 1280px) {
  .heading-1 {
    font-size: 5.3rem;
  }
}
@media (max-width: 1024px) {
  .heading-1 {
    font-size: 4.2rem;
  }
}
@media (max-width: 767px) {
  .heading-1 {
    letter-spacing: 0.5px;
  }
}

.heading-2 {
  font-size: 7.7rem;
  line-height: 10rem;
  letter-spacing: 1px;
  font-weight: 300;
}
@media (max-width: 1680px) {
  .heading-2 {
    font-size: 6.7rem;
    line-height: 8.8rem;
  }
}
@media (max-width: 1440px) {
  .heading-2 {
    font-size: 5.7rem;
    line-height: 7.5rem;
  }
}
@media (max-width: 1366px) {
  .heading-2 {
    font-size: 5.3rem;
    line-height: 7.1rem;
  }
}
@media (max-width: 1280px) {
  .heading-2 {
    font-size: 5rem;
    line-height: 6.6rem;
  }
}
@media (max-width: 1024px) {
  .heading-2 {
    font-size: 4.2rem;
    line-height: 5.3rem;
  }
}
@media (max-width: 767px) {
  .heading-2 {
    letter-spacing: 0.5px;
  }
}
@media (max-width: 575px) {
  .heading-2 {
    font-size: 3.5rem;
    line-height: 4.9rem;
  }
}
@media (max-width: 467px) {
  .heading-2 {
    font-size: 3.1rem;
    line-height: 4.5rem;
  }
}
@media (max-width: 359px) {
  .heading-2 {
    font-size: 2.7rem;
    line-height: 4rem;
  }
}

.heading-3 {
  font-size: 5.9rem;
  letter-spacing: 1px;
  font-weight: 300;
}
@media (max-width: 1680px) {
  .heading-3 {
    font-size: 5.1rem;
  }
}
@media (max-width: 1440px) {
  .heading-3 {
    font-size: 4.4rem;
  }
}
@media (max-width: 1366px) {
  .heading-3 {
    font-size: 4.1rem;
  }
}
@media (max-width: 1280px) {
  .heading-3 {
    font-size: 3.9rem;
  }
}
@media (max-width: 1024px) {
  .heading-3 {
    font-size: 3.2rem;
  }
}
@media (max-width: 767px) {
  .heading-3 {
    letter-spacing: 0.5px;
  }
}
@media (max-width: 467px) {
  .heading-3 {
    font-size: 2.7rem;
  }
}

.heading-4 {
  font-size: 3.4rem;
  letter-spacing: 1px;
  font-weight: 300;
}
@media (max-width: 1680px) {
  .heading-4 {
    font-size: 2.9rem;
  }
}
@media (max-width: 1440px) {
  .heading-4 {
    font-size: 2.5rem;
  }
}
@media (max-width: 1366px) {
  .heading-4 {
    font-size: 2.4rem;
  }
}
@media (max-width: 1280px) {
  .heading-4 {
    font-size: 2.2rem;
  }
}
@media (max-width: 1024px) {
  .heading-4 {
    font-size: 1.7rem;
  }
}
@media (max-width: 767px) {
  .heading-4 {
    letter-spacing: 0.5px;
  }
}

.desc-1 {
  font-size: 2.7rem;
  letter-spacing: 1px;
  font-weight: 300;
}
@media (max-width: 1680px) {
  .desc-1 {
    font-size: 2.3rem;
  }
}
@media (max-width: 1440px) {
  .desc-1 {
    font-size: 2rem;
  }
}
@media (max-width: 1366px) {
  .desc-1 {
    font-size: 1.8rem;
  }
}
@media (max-width: 1280px) {
  .desc-1 {
    font-size: 1.7rem;
  }
}
@media (max-width: 1024px) {
  .desc-1 {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  .desc-1 {
    color: #ffffff;
    letter-spacing: 0.5px;
  }
}

.desc-2 {
  font-size: 2.3rem;
  line-height: 2.8rem;
  letter-spacing: 1px;
  font-weight: 300;
}
@media (max-width: 1680px) {
  .desc-2 {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}
@media (max-width: 1440px) {
  .desc-2 {
    font-size: 1.7rem;
    line-height: 2.1rem;
  }
}
@media (max-width: 1366px) {
  .desc-2 {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
}
@media (max-width: 1280px) {
  .desc-2 {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }
}
@media (max-width: 1024px) {
  .desc-2 {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
}
@media (max-width: 767px) {
  .desc-2 {
    letter-spacing: 0.5px;
  }
}

.desc-3 {
  font-size: 1.9rem;
  line-height: 3rem;
  letter-spacing: 1px;
  font-weight: 300;
}
@media (max-width: 1680px) {
  .desc-3 {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}
@media (max-width: 1440px) {
  .desc-3 {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}
@media (max-width: 1366px) {
  .desc-3 {
    font-size: 1.3rem;
    line-height: 2.1rem;
  }
}
@media (max-width: 1280px) {
  .desc-3 {
    font-size: 1.2rem;
    line-height: 2rem;
  }
}
@media (max-width: 1024px) {
  .desc-3 {
    font-size: 0.9rem;
    line-height: 1.6rem;
  }
}
@media (max-width: 767px) {
  .desc-3 {
    letter-spacing: 0.5px;
  }
}

.icon-arrow-right {
  margin-left: 10px;
  font-size: 10px;
  display: inline-block;
  transition: 0.5s ease;
}

.primary-btn {
  position: relative;
  padding: 1.5rem 2.5rem;
  font-size: 2.2rem;
  letter-spacing: 1px;
  border: 1px solid var(--border-color);
  overflow: hidden;
  border-radius: 0;
  font-weight: 300;
}
.primary-btn:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #EB0C0C;
  transition: all 0.3s;
  z-index: -1;
}
.primary-btn:hover {
  border: 1px solid #EB0C0C;
  color: #ffffff !important;
}
.primary-btn:hover:before {
  width: 100%;
}
.primary-btn:focus, .primary-btn:active {
  outline: none;
  box-shadow: none;
}
@media (max-width: 1680px) {
  .primary-btn {
    font-size: 1.9rem;
    padding: 1.3rem 2.1rem;
  }
}
@media (max-width: 1440px) {
  .primary-btn {
    font-size: 1.6rem;
    padding: 1.1rem 1.8rem;
  }
}
@media (max-width: 1366px) {
  .primary-btn {
    font-size: 1.5rem;
    padding: 1.1rem 1.7rem;
  }
}
@media (max-width: 1280px) {
  .primary-btn {
    font-size: 1.4rem;
    padding: 1rem 1.6rem;
  }
}
@media (max-width: 1024px) {
  .primary-btn {
    padding: 0.8rem 1.3rem;
  }
}
.primary-btn--nav {
  padding: 0.8rem 1.5rem;
  font-size: 1.15rem;
  font-weight: 300;
  border-radius: 1rem;
}

.secondary-btn {
  padding: 1.8rem 4rem;
  color: #ffffff !important;
  font-size: 1.9rem;
  letter-spacing: 1px;
  font-weight: 300;
  background: #EB0C0C;
  border: 0.1rem solid #EB0C0C;
  transition: 0.5s ease;
  border-radius: 0;
}
.secondary-btn:hover {
  background-color: #000000;
  border: 0.1rem solid #ffffff;
}
.secondary-btn:hover .icon-arrow-right {
  transform: translateX(5px);
}
.secondary-btn:focus, .secondary-btn:active {
  outline: none;
  box-shadow: none;
}
@media (max-width: 1680px) {
  .secondary-btn {
    font-size: 1.6rem;
    padding: 1.5rem 3.5rem;
  }
}
@media (max-width: 1440px) {
  .secondary-btn {
    font-size: 1.4rem;
    padding: 1.3rem 3rem;
  }
}
@media (max-width: 1366px) {
  .secondary-btn {
    font-size: 1.3rem;
    padding: 1.2rem 2.8rem;
  }
}
@media (max-width: 1280px) {
  .secondary-btn {
    font-size: 1.2rem;
    padding: 1.2rem 2.6rem;
  }
}
@media (max-width: 1024px) {
  .secondary-btn {
    font-size: 1.4rem;
    padding: 0.9rem 2.1rem;
  }
}

.back-btn {
  display: inline-flex !important;
  align-items: center;
  padding: 0.5rem 1.2rem;
  font-size: 1.5rem;
  border: 1px solid var(--border-color);
  opacity: 0.8;
  border-radius: 1rem;
  line-height: 1;
  font-weight: 300;
}
.back-btn .icon-angle-left {
  font-size: 1.5rem;
  transition: 0.3s all;
  display: inline-block;
  margin-right: 0.5rem;
}
@media (max-width: 767px) {
  .back-btn .icon-angle-left {
    margin-right: 0.4rem;
    font-size: 1.7rem;
  }
}
.back-btn:hover .icon-angle-left {
  transform: translateX(-5px);
}
.back-btn:focus, .back-btn:active {
  outline: none;
  box-shadow: none;
}
@media (max-width: 767px) {
  .back-btn {
    padding: 0.5rem 1rem;
    font-size: 1.3rem;
  }
}

.mute_btn {
  position: absolute;
  display: flex !important;
  align-items: center;
  justify-content: center;
  right: 1rem;
  bottom: 1rem;
  background: rgba(0, 0, 0, 0.5294117647);
  color: #fff;
  height: 4rem;
  cursor: pointer;
  line-height: 4rem;
  width: 4rem;
  border-radius: 50%;
}
.mute_btn .hide {
  display: none;
}
.mute_btn:focus, .mute_btn:active {
  outline: none;
  box-shadow: none;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: var(--loader-color);
}
.loader-wrapper .loader {
  border: 0.8rem solid #EB0C0C;
  border-radius: 50%;
  border-top: 0.8rem solid #D9D9D9;
  width: 6rem;
  height: 6rem;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.header {
  top: 0;
  z-index: 100;
  height: 6.4rem;
  backdrop-filter: blur(1.5rem);
  background-color: var(--header-bg);
}
.header-logo {
  width: 15rem;
}
@media (max-width: 767px) {
  .header-logo {
    margin-left: 3rem;
  }
}
@media (max-width: 359px) {
  .header-logo {
    margin-left: 2rem;
  }
}
.header .navbar {
  padding: 1rem 0;
}
.header .navbar-brand {
  z-index: 1;
}
@media (max-width: 991px) {
  .header .navbar-brand {
    order: 2;
  }
}
@media (max-width: 767px) {
  .header .navbar-brand {
    margin-right: 0;
  }
}
.header .navbar-nav .nav-item {
  margin: 0 2rem 0 0;
}
.header .navbar-nav .nav-item .nav-link {
  color: #ffffff;
  font-size: 1.43rem;
  background: linear-gradient(to right, #EB0C0C, #EB0C0C 50%, var(--font-color) 50%);
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.4s ease-in-out;
  font-weight: 300;
}
.header .navbar-nav .nav-item .nav-link:before {
  content: "";
  background: #EB0C0C;
  display: block;
  position: absolute;
  bottom: -0.3rem;
  left: 0;
  width: 0;
  height: 0.2rem;
  transition: all 0.4s ease-in-out;
}
.header .navbar-nav .nav-item .nav-link:hover {
  background-position: 0;
}
.header .navbar-nav .nav-item .nav-link:hover::before {
  width: 100%;
}
@media (max-width: 991px) {
  .header .navbar-nav .nav-item {
    margin-right: 0;
    padding: 1.2rem;
    border-bottom: 0.05rem solid var(--border-color);
  }
  .header .navbar-nav .nav-item:first-child {
    padding-top: 0;
  }
}
@media (max-width: 991px) {
  .header .navbar-nav {
    padding: 2rem 2.3rem;
  }
}
@media (max-width: 767px) {
  .header .navbar-nav {
    padding: 2rem 1rem;
  }
}
.header .navbar_dl-switch {
  /* Rounded sliders */
}
.header .navbar_dl-switch .switch {
  position: relative;
  display: inline-block;
  width: 6rem;
  height: 3.4rem;
}
.header .navbar_dl-switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.header .navbar_dl-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D9D9D9;
  transition: 0.4s;
}
.header .navbar_dl-switch .slider:before {
  position: absolute;
  content: "";
  height: 2.6rem;
  width: 2.6rem;
  left: 0.4rem;
  bottom: 0.4rem;
  background: url("../images/light-switch.svg") no-repeat center center;
  background-size: cover;
  transition: 0.4s;
  box-shadow: 0rem 0.4rem 0.3rem 0rem rgba(0, 0, 0, 0.55);
}
.header .navbar_dl-switch input:checked + .slider {
  background-color: #1B1B1B;
}
.header .navbar_dl-switch input:checked + .slider:before {
  transform: translateX(2.6rem);
  background: url("../images/dark-switch.svg") no-repeat center center;
  background-size: cover;
  box-shadow: 0rem 0.4rem 0.3rem 0rem rgba(0, 0, 0, 0.55);
}
.header .navbar_dl-switch input:focus + .slider {
  box-shadow: 0 0 0.1rem #1b1b1b;
}
.header .navbar_dl-switch .slider.round {
  border-radius: 3.4rem;
}
.header .navbar_dl-switch .slider.round:before {
  border-radius: 50%;
}
@media (max-width: 991px) {
  .header .navbar_dl-switch {
    order: 3;
  }
}
.header .navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
  outline: none;
  box-shadow: none;
  border: none;
  position: relative;
}
.header .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
  border: none;
  position: relative;
}
.header .navbar-toggler:active {
  outline: none;
  box-shadow: none;
  border: none;
  position: relative;
}
.header .navbar-toggler span {
  margin: 0;
  padding: 0;
}
.header .navbar-toggler .top-bar {
  top: inherit;
  transform: rotate(135deg);
}
.header .navbar-toggler .middle-bar {
  opacity: 0;
  top: inherit;
}
.header .navbar-toggler .bottom-bar {
  top: inherit;
  transform: rotate(-135deg);
}
.header .navbar-toggler.collapsed .top-bar {
  position: absolute;
  top: 0;
  transform: rotate(0deg);
}
.header .navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  position: absolute;
  top: 10px;
}
.header .navbar-toggler.collapsed .bottom-bar {
  position: absolute;
  top: 20px;
  transform: rotate(0deg);
}
@media (max-width: 991px) {
  .header .navbar-collapse {
    position: fixed;
    top: 0;
    padding-top: 6.4rem;
    left: -100vw;
    width: 100vw;
    height: 100vh;
    background: var(--bg-color);
    display: block !important;
    transition: 0.3s ease-in;
  }
}
.header .navbar .show {
  left: 0;
}
@media (max-width: 991px) {
  .header .navbar {
    padding: 1.5rem 1rem;
  }
}
.header .toggler-icon {
  display: block;
  position: absolute;
  height: 0.26rem;
  width: 2.4rem;
  background: var(--toggler-color);
  border-radius: 0.1rem;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.3s ease-in;
  z-index: 1;
}
.header .middle-bar {
  margin-top: 0;
}

html[data-theme=dark] .header-logo {
  filter: brightness(0) invert(1);
}

.footer {
  padding: 2rem 0rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 300;
  background-color: var(--tabs-color);
  letter-spacing: 0.5px;
}
.footer__desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .footer__desc {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.footer__desc-left, .footer__desc-right {
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .footer__desc-left {
    margin-bottom: 1rem;
  }
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}

.digital {
  padding: 0;
}
.digital__title {
  padding: 10rem 0;
}
@media (max-width: 1680px) {
  .digital__title {
    padding: 8.7rem 0;
  }
}
@media (max-width: 1440px) {
  .digital__title {
    padding: 7.5rem 0;
  }
}
@media (max-width: 1366px) {
  .digital__title {
    padding: 7.1rem 0;
  }
}
@media (max-width: 1280px) {
  .digital__title {
    padding: 6.6rem 0;
  }
}
@media (max-width: 1024px) {
  .digital__title {
    padding: 5.3rem 0;
  }
}
.digital-wrapper {
  height: 100%;
  position: relative;
}
.digital-wrapper__video-box {
  height: calc(100vh - 60px);
  pointer-events: none;
}
.digital-wrapper__video-box .desk-video {
  display: block;
}
.digital-wrapper__video-box .mob-video {
  display: none;
}
.digital-wrapper__video-box__video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .digital-wrapper__video-box .desk-video {
    display: none;
  }
  .digital-wrapper__video-box .mob-video {
    display: block;
  }
}
.digital-wrapper__contents {
  display: flex;
  flex-direction: column;
}
.digital-wrapper__contents-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-top: 10rem;
}
.digital-wrapper__contents-list .list-items {
  padding: 5% 3%;
  width: 25%;
  position: relative;
}
.digital-wrapper__contents-list .list-items:after {
  content: "";
  position: absolute;
  background-color: red;
  height: 1px;
  width: 50%;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
}
.digital-wrapper__contents-list .list-items__title {
  margin-top: 2rem;
  margin-bottom: 0;
}
@media (max-width: 1680px) {
  .digital-wrapper__contents-list .list-items__title {
    margin-top: 1.7rem;
  }
}
@media (max-width: 1440px) {
  .digital-wrapper__contents-list .list-items__title {
    margin-top: 1.5rem;
  }
}
@media (max-width: 1366px) {
  .digital-wrapper__contents-list .list-items__title {
    margin-top: 1.4rem;
  }
}
@media (max-width: 1280px) {
  .digital-wrapper__contents-list .list-items__title {
    margin-top: 1.3rem;
  }
}
@media (max-width: 1024px) {
  .digital-wrapper__contents-list .list-items__title {
    margin-top: 1rem;
  }
}
.digital-wrapper__contents-list .list-items__numbers {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .digital-wrapper__contents-list .list-items {
    width: 100%;
    padding: 3rem 0;
  }
}
@media (max-width: 1680px) {
  .digital-wrapper__contents-list {
    padding-top: 8.7rem;
  }
}
@media (max-width: 1440px) {
  .digital-wrapper__contents-list {
    padding-top: 7.5rem;
  }
}
@media (max-width: 1366px) {
  .digital-wrapper__contents-list {
    padding-top: 7.1rem;
  }
}
@media (max-width: 1280px) {
  .digital-wrapper__contents-list {
    padding-top: 6.6rem;
  }
}
@media (max-width: 1024px) {
  .digital-wrapper__contents-list {
    padding-top: 5.3rem;
  }
}
@media (max-width: 767px) {
  .digital-wrapper__contents-list {
    text-align: center;
    padding: 1rem;
  }
}

.our-work {
  padding-top: 10rem;
  padding-bottom: 0;
}
.our-work__tabs {
  padding: 5rem 0;
}
.our-work__tabs .main-tabs {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  overflow: auto;
  flex-wrap: nowrap;
  border-bottom: 0;
}
.our-work__tabs .main-tabs .nav-item .nav-link {
  margin-bottom: 0px;
  border: none;
  border-radius: 0;
  padding: 2rem 4rem;
  cursor: pointer;
  border-bottom: 0.1rem solid transparent;
  transition: all 0.5s ease;
  font-weight: 300;
}
.our-work__tabs .main-tabs .nav-item .nav-link span {
  text-align: center;
  width: 3rem;
  position: relative;
  display: inline-block;
  left: 6rem;
  opacity: 0;
  transition: all 0.3s ease 0s;
}
.our-work__tabs .main-tabs .nav-item .nav-link span .icon-php {
  display: inline-flex;
  font-size: 4rem;
  transform: translateY(3px);
}
@media (max-width: 1680px) {
  .our-work__tabs .main-tabs .nav-item .nav-link span .icon-php {
    transform: translateY(5px);
  }
}
@media (max-width: 1440px) {
  .our-work__tabs .main-tabs .nav-item .nav-link span .icon-php {
    transform: translateY(7px);
  }
}
@media (max-width: 1280px) {
  .our-work__tabs .main-tabs .nav-item .nav-link span .icon-php {
    transform: translateY(6px);
    font-size: 3.5rem;
  }
}
@media (max-width: 1024px) {
  .our-work__tabs .main-tabs .nav-item .nav-link span .icon-php {
    font-size: 3rem;
  }
}
.our-work__tabs .main-tabs .nav-item .nav-link.active span, .our-work__tabs .main-tabs .nav-item .nav-link:hover span {
  left: -2rem;
  opacity: 1;
}
@media (max-width: 1024px) {
  .our-work__tabs .main-tabs .nav-item .nav-link.active span, .our-work__tabs .main-tabs .nav-item .nav-link:hover span {
    left: -1rem;
  }
}
.our-work__tabs .main-tabs .nav-item .active {
  border-bottom: 0.1rem solid #EB0C0C;
  background-color: transparent;
}
@media (max-width: 767px) {
  .our-work__tabs .main-tabs .nav-item {
    margin-right: 2rem;
    min-width: 50vw;
    text-align: center;
  }
  .our-work__tabs .main-tabs .nav-item:last-child {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .our-work__tabs .main-tabs {
    white-space: nowrap;
    justify-content: flex-start;
  }
  .our-work__tabs .main-tabs::-webkit-scrollbar {
    border: none;
  }
}
.our-work__tabs .inner-main-wrapper {
  padding: 5rem 0;
}
.our-work__tabs .inner-main-wrapper .row:hover .inner-contents-box__subhead-1::after {
  width: 100%;
}
@media (max-width: 767px) {
  .our-work__tabs .inner-main-wrapper .row .inner-contents-box__subhead-1::after {
    width: 100% !important;
  }
}
.our-work__tabs .inner-main-wrapper .hide {
  display: none;
}
.our-work__tabs .inner-main-wrapper .inner-border {
  padding: 10rem;
  border: 0.1rem solid var(--border-color);
}
.our-work__tabs .inner-main-wrapper .inner-border .inner-contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  margin-top: 5rem;
}
.our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__subhead-1 {
  display: inline-block;
  margin-bottom: 1.4rem;
  line-height: 3rem;
}
.our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__subhead-1:after {
  content: "";
  display: block;
  width: 0%;
  height: 3px;
  background: #EB0C0C;
  transition: width 0.3s;
}
@media (max-width: 1680px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__subhead-1 {
    margin-bottom: 1.2rem;
  }
}
@media (max-width: 1440px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__subhead-1 {
    margin-bottom: 1rem;
  }
}
@media (max-width: 1366px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__subhead-1 {
    margin-bottom: 0.9rem;
  }
}
@media (max-width: 1280px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__subhead-1 {
    margin-bottom: 0.9rem;
  }
}
@media (max-width: 1024px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__subhead-1 {
    margin-bottom: 0.7rem;
  }
}
.our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__title {
  margin-bottom: 3rem;
}
@media (max-width: 1680px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__title {
    margin-bottom: 2.6rem;
  }
}
@media (max-width: 1440px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__title {
    margin-bottom: 2.2rem;
  }
}
@media (max-width: 1366px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__title {
    margin-bottom: 2.1rem;
  }
}
@media (max-width: 1280px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__title {
    margin-bottom: 2rem;
  }
}
@media (max-width: 1024px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__title {
    margin-bottom: 1.6rem;
  }
}
.our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__subhead-2 {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__subhead-2 {
    justify-content: center;
    margin-bottom: 1.5rem;
  }
}
.our-work__tabs .inner-main-wrapper .inner-border .inner-contents-box__subhead-2 i {
  border: 0.05rem solid var(--border-color);
  border-radius: 0.5rem;
  margin-left: 0.5rem;
  padding: 0.5rem;
}
.our-work__tabs .inner-main-wrapper .inner-border .inner-contents__video-box__video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 1680px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents {
    margin-top: 4.3rem;
  }
}
@media (max-width: 1440px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents {
    margin-top: 3.7rem;
  }
}
@media (max-width: 1366px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents {
    margin-top: 3.5rem;
  }
}
@media (max-width: 1280px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents {
    margin-top: 3.3rem;
  }
}
@media (max-width: 1024px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents {
    margin-top: 2.6rem;
  }
}
@media (max-width: 767px) {
  .our-work__tabs .inner-main-wrapper .inner-border .inner-contents {
    height: 100%;
    text-align: center;
  }
}
.our-work__tabs .inner-main-wrapper .inner-border:nth-child(odd) {
  border-right: 0;
}
@media (max-width: 767px) {
  .our-work__tabs .inner-main-wrapper .inner-border:nth-child(odd) {
    border-right: 0.1rem solid var(--border-color);
    border-bottom: 0.1rem solid var(--border-color);
    border-top: 0;
    padding-top: 0;
  }
}
@media (max-width: 1680px) {
  .our-work__tabs .inner-main-wrapper .inner-border {
    padding: 8.7rem;
  }
}
@media (max-width: 1440px) {
  .our-work__tabs .inner-main-wrapper .inner-border {
    padding: 7.5rem;
  }
}
@media (max-width: 1366px) {
  .our-work__tabs .inner-main-wrapper .inner-border {
    padding: 7.1rem;
  }
}
@media (max-width: 1280px) {
  .our-work__tabs .inner-main-wrapper .inner-border {
    padding: 6.6rem;
  }
}
@media (max-width: 1024px) {
  .our-work__tabs .inner-main-wrapper .inner-border {
    padding: 5.3rem;
  }
}
@media (max-width: 767px) {
  .our-work__tabs .inner-main-wrapper .inner-border {
    padding: 3.9rem;
  }
  .our-work__tabs .inner-main-wrapper .inner-border:nth-child(even) {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 1680px) {
  .our-work__tabs .inner-main-wrapper {
    padding: 4.3rem 0;
  }
}
@media (max-width: 1440px) {
  .our-work__tabs .inner-main-wrapper {
    padding: 3.7rem 0;
  }
}
@media (max-width: 1366px) {
  .our-work__tabs .inner-main-wrapper {
    padding: 3.5rem 0;
  }
}
@media (max-width: 1280px) {
  .our-work__tabs .inner-main-wrapper {
    padding: 3.3rem 0;
  }
}
@media (max-width: 1024px) {
  .our-work__tabs .inner-main-wrapper {
    padding: 2.6rem 0;
  }
}
@media (max-width: 767px) {
  .our-work__tabs .inner-main-wrapper .row {
    flex-direction: column-reverse;
  }
}
.our-work__tabs .row:nth-child(even) .inner-border {
  border-top: none;
  border-bottom: none;
}
.our-work__tabs .row:nth-child(even):last-child {
  border-bottom: 0.1rem solid var(--border-color);
}
@media (max-width: 1680px) {
  .our-work__tabs {
    padding: 4.3rem 0;
  }
}
@media (max-width: 1440px) {
  .our-work__tabs {
    padding: 3.7rem 0;
  }
}
@media (max-width: 1366px) {
  .our-work__tabs {
    padding: 3.5rem 0;
  }
}
@media (max-width: 1280px) {
  .our-work__tabs {
    padding: 3.3rem 0;
  }
}
@media (max-width: 1024px) {
  .our-work__tabs {
    padding: 2.6rem 0;
  }
}
.our-work__grp {
  padding: 5rem 0;
}
.our-work__grp .hide {
  display: none !important;
}
@media (max-width: 1680px) {
  .our-work__grp {
    padding: 4.3rem 0;
  }
}
@media (max-width: 1440px) {
  .our-work__grp {
    padding: 3.7rem 0;
  }
}
@media (max-width: 1366px) {
  .our-work__grp {
    padding: 3.5rem 0;
  }
}
@media (max-width: 1280px) {
  .our-work__grp {
    padding: 3.3rem 0;
  }
}
@media (max-width: 1024px) {
  .our-work__grp {
    padding: 2.6rem 0;
  }
}
@media (max-width: 1680px) {
  .our-work {
    padding-top: 8.7rem;
  }
}
@media (max-width: 1440px) {
  .our-work {
    padding-top: 7.5rem;
  }
}
@media (max-width: 1366px) {
  .our-work {
    padding-top: 7.1rem;
  }
}
@media (max-width: 1280px) {
  .our-work {
    padding-top: 6.6rem;
  }
}
@media (max-width: 1024px) {
  .our-work {
    padding-top: 5.3rem;
  }
}
@media (max-width: 767px) {
  .our-work #custom-cursor {
    display: none;
  }
}

.hero-banner {
  padding: 0;
}
.hero-banner__wrapper {
  padding-top: 10rem;
}
.hero-banner__wrapper .button-wrapper {
  display: flex;
  justify-content: space-between;
}
.hero-banner__wrapper .button-wrapper__right {
  min-width: 8rem;
  text-align: right;
}
.hero-banner__wrapper-imgbox__img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/*Quote*/
.quote {
  padding-top: 10rem;
}
@media (max-width: 767px) {
  .quote-wrapper__title-subhead {
    color: var(--font-color);
  }
}
@media (max-width: 1680px) {
  .quote {
    padding-top: 8.7rem;
  }
}
@media (max-width: 1440px) {
  .quote {
    padding-top: 7.5rem;
  }
}
@media (max-width: 1366px) {
  .quote {
    padding-top: 7.1rem;
  }
}
@media (max-width: 1280px) {
  .quote {
    padding-top: 6.6rem;
  }
}
@media (max-width: 1024px) {
  .quote {
    padding-top: 5.3rem;
  }
}

.grid {
  padding-bottom: 1rem;
}
.grid__wrapper {
  column-count: 2;
  column-gap: 2rem;
}
@media (max-width: 767px) {
  .grid__wrapper {
    column-count: 1;
  }
}
@media (max-width: 767px) {
  .grid__box {
    margin-bottom: 2rem;
  }
  .grid__box:last-child {
    margin-bottom: 0;
  }
}
.grid__box-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.challenges__title {
  text-align: center;
}
@media (max-width: 767px) {
  .challenges__title .heading-1 {
    font-size: 3.5rem;
  }
}
@media (max-width: 359px) {
  .challenges__title .heading-1 {
    font-size: 3rem;
  }
}
.challenges__wrapper {
  padding: 5rem 0;
}
.challenges__wrapper__list {
  position: relative;
}
.challenges__wrapper__list-item {
  display: flex;
  align-items: center;
  border: 0.05rem solid var(--card-border-color);
  position: relative;
}
.challenges__wrapper__list-item__left, .challenges__wrapper__list-item__right {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 7rem 8rem;
}
@media (max-width: 1680px) {
  .challenges__wrapper__list-item__left, .challenges__wrapper__list-item__right {
    padding: 6.1rem 7rem;
  }
}
@media (max-width: 1440px) {
  .challenges__wrapper__list-item__left, .challenges__wrapper__list-item__right {
    padding: 5.2rem 6rem;
  }
}
@media (max-width: 1366px) {
  .challenges__wrapper__list-item__left, .challenges__wrapper__list-item__right {
    padding: 4.9rem 5.6rem;
  }
}
@media (max-width: 1280px) {
  .challenges__wrapper__list-item__left, .challenges__wrapper__list-item__right {
    padding: 4.6rem 5.3rem;
  }
}
@media (max-width: 1024px) {
  .challenges__wrapper__list-item__left, .challenges__wrapper__list-item__right {
    padding: 3.7rem 4.2rem;
  }
}
@media (max-width: 767px) {
  .challenges__wrapper__list-item__left, .challenges__wrapper__list-item__right {
    padding: 2rem;
  }
}
.challenges__wrapper__list-item__left .heading-1 {
  margin-right: 3rem;
  margin-bottom: 0;
}
@media (max-width: 1680px) {
  .challenges__wrapper__list-item__left .heading-1 {
    margin-right: 2.6rem;
  }
}
@media (max-width: 1440px) {
  .challenges__wrapper__list-item__left .heading-1 {
    margin-right: 2.2rem;
  }
}
@media (max-width: 1366px) {
  .challenges__wrapper__list-item__left .heading-1 {
    margin-right: 2.1rem;
  }
}
@media (max-width: 1280px) {
  .challenges__wrapper__list-item__left .heading-1 {
    margin-right: 2rem;
  }
}
@media (max-width: 1024px) {
  .challenges__wrapper__list-item__left .heading-1 {
    margin-right: 1.6rem;
  }
}
@media (max-width: 767px) {
  .challenges__wrapper__list-item__left .heading-1 {
    position: absolute;
    top: -1.2rem;
    left: 1.4rem;
    padding-left: 0.5rem;
    font-size: 2rem;
    background-color: var(--bg-color);
  }
}
@media (max-width: 767px) {
  .challenges__wrapper__list-item__left {
    flex-direction: column;
  }
}
.challenges__wrapper__list-item:after {
  content: "";
  position: absolute;
  height: 100%;
  border-left: 0.05rem solid var(--card-border-color);
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  bottom: 0;
}
.challenges__wrapper__list-item:nth-child(2) {
  border-top: none;
  border-bottom: none;
}
@media (max-width: 767px) {
  .challenges__wrapper__list-item {
    align-items: flex-start;
  }
}
@media (max-width: 1680px) {
  .challenges__wrapper {
    padding: 4.3rem 0;
  }
}
@media (max-width: 1440px) {
  .challenges__wrapper {
    padding: 3.7rem 0;
  }
}
@media (max-width: 1366px) {
  .challenges__wrapper {
    padding: 3.5rem 0;
  }
}
@media (max-width: 1280px) {
  .challenges__wrapper {
    padding: 3.3rem 0;
  }
}
@media (max-width: 1024px) {
  .challenges__wrapper {
    padding: 2.6rem 0;
  }
}

.stack__head {
  margin-bottom: 6rem;
}
@media (max-width: 1680px) {
  .stack__head {
    margin-bottom: 5.2rem;
  }
}
@media (max-width: 1440px) {
  .stack__head {
    margin-bottom: 4.5rem;
  }
}
@media (max-width: 1366px) {
  .stack__head {
    margin-bottom: 4.2rem;
  }
}
@media (max-width: 1280px) {
  .stack__head {
    margin-bottom: 4rem;
  }
}
@media (max-width: 1024px) {
  .stack__head {
    margin-bottom: 3.2rem;
  }
}
.stack__border {
  border-right: 0.5px solid rgba(111, 110, 110, 0.431372549);
}
.stack__border:last-child {
  border-right: 0;
}
@media (max-width: 767px) {
  .stack__border {
    border-top: 0.5px solid rgba(111, 110, 110, 0.431372549);
  }
}
.stack__title {
  min-height: 75px;
  margin-bottom: 2.5rem;
  border-bottom: 0.5px solid rgba(111, 110, 110, 0.431372549);
}
@media (max-width: 767px) {
  .stack__title {
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.stack__list {
  display: flex;
  flex-direction: column;
  user-select: none;
}
.stack__list-item {
  margin-bottom: 1.5rem;
}
.stack__list-item:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .stack__list-item:last-child {
    margin-bottom: 3rem;
  }
}
.stack__img {
  display: inline-flex;
  align-items: center;
  background-color: var(--tabs-color);
  padding: 1.5rem 2rem;
  height: 10rem;
  width: 10rem;
}
@media (max-width: 767px) {
  .stack .row [class*=col-]:nth-child(even) {
    border-right: 0;
  }
}

.btm-grid {
  padding-top: 1rem;
}
.btm-grid__wrapper {
  display: flex;
  gap: 2rem;
}
@media (max-width: 767px) {
  .btm-grid__wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .highlights__title {
    text-align: center;
  }
}
.highlights__list {
  counter-reset: num;
  position: relative;
}
.highlights__list > li + li {
  border-top: 0.1rem solid #4a4a4a;
}
.highlights__list-desc {
  position: relative;
  counter-increment: num;
  padding-left: 5rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.highlights__list-desc:before {
  content: "0" counter(num) ".";
  position: absolute;
  left: 0;
  top: 4.2rem;
  color: #797979;
  font-weight: 900;
  font-size: 2.8rem;
}
@media (max-width: 1680px) {
  .highlights__list-desc:before {
    font-size: 2.4rem;
    top: 3.6rem;
  }
}
@media (max-width: 1440px) {
  .highlights__list-desc:before {
    font-size: 2.1rem;
    top: 3.2rem;
  }
}
@media (max-width: 1366px) {
  .highlights__list-desc:before {
    font-size: 1.9rem;
    top: 3rem;
  }
}
@media (max-width: 1280px) {
  .highlights__list-desc:before {
    font-size: 1.8rem;
    top: 2.7rem;
  }
}
@media (max-width: 1024px) {
  .highlights__list-desc:before {
    font-size: 1.5rem;
    top: 2.3rem;
  }
}
@media (max-width: 767px) {
  .highlights__list-desc:before {
    left: 2rem;
    top: 2.2rem;
  }
}
@media (max-width: 1680px) {
  .highlights__list-desc {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
}
@media (max-width: 1440px) {
  .highlights__list-desc {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
@media (max-width: 1366px) {
  .highlights__list-desc {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem;
  }
}
@media (max-width: 1280px) {
  .highlights__list-desc {
    padding-top: 2.6rem;
    padding-bottom: 2.6rem;
  }
}
@media (max-width: 1024px) {
  .highlights__list-desc {
    padding-top: 2.1rem;
    padding-bottom: 2.1rem;
  }
}
.highlights__list-desc .list-head {
  margin: 0;
  color: var(--font-color);
}
.highlights__list-desc .sublist {
  padding-left: 3rem;
  margin-top: 2.8rem;
}
.highlights__list-desc .sublist__item {
  display: list-item;
  list-style-type: disc;
  padding: 0.5rem 0;
}
@media (max-width: 1680px) {
  .highlights__list-desc .sublist {
    margin-top: 2.4rem;
  }
}
@media (max-width: 1440px) {
  .highlights__list-desc .sublist {
    margin-top: 2.1rem;
  }
}
@media (max-width: 1366px) {
  .highlights__list-desc .sublist {
    margin-top: 1.9rem;
  }
}
@media (max-width: 1280px) {
  .highlights__list-desc .sublist {
    margin-top: 1.8rem;
  }
}
@media (max-width: 1024px) {
  .highlights__list-desc .sublist {
    margin-top: 1.5rem;
  }
}
@media (max-width: 767px) {
  .highlights__list-desc .sublist {
    padding-left: 2rem;
  }
}

.project {
  overflow: hidden;
}
.project .swiper-slide {
  transition: transform 0.3s ease 0s;
  opacity: 0.5;
}
@media (max-width: 767px) {
  .project .swiper-slide {
    opacity: 0.7;
  }
}
.project .swiper-slide-active {
  transform: translateY(-50px);
  opacity: 1;
}
@media (max-width: 767px) {
  .project .swiper-slide-active {
    transform: translateY(-20px);
  }
}
@media (max-width: 767px) {
  .project {
    padding-top: 6rem;
  }
  .project #swip-cursor {
    display: none;
  }
  .project .swiper-container {
    padding: 0 2rem;
  }
}
.project-slider__details {
  border: 1px solid #afafaf;
}

.upcoming__slider__wrapper {
  background: var(--tabs-color);
  padding: 3rem 4rem;
}
.upcoming__slider__wrapper .slider-row {
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .upcoming__slider__wrapper .slider-row {
    flex-direction: column-reverse;
  }
}
.upcoming__slider__wrapper .slider-col {
  margin-top: 2rem;
}
.upcoming__slider__wrapper .slider-link {
  transition: 0.5s ease;
  text-decoration: none !important;
}
.upcoming__slider__wrapper .slider-link:hover {
  text-decoration: underline !important;
}
.upcoming__slider .slick-next,
.upcoming__slider .slick-prev {
  position: absolute;
  display: block;
  line-height: 0;
  font-size: 0;
  font-family: "icomoon" !important;
  cursor: pointer;
  background: 0 0;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: 0;
  outline: 0;
  z-index: 5;
}
.upcoming__slider .slick-prev {
  left: -30px;
}
.upcoming__slider .slick-prev:before {
  content: "\e901";
  position: absolute;
  font-size: 35px;
  color: var(--font-color);
}
@media (max-width: 767px) {
  .upcoming__slider .slick-prev {
    left: 12px;
  }
}
.upcoming__slider .slick-next {
  right: -20px;
}
.upcoming__slider .slick-next:before {
  content: "";
  content: "\e900";
  position: absolute;
  font-size: 35px;
  color: var(--font-color);
}
@media (max-width: 767px) {
  .upcoming__slider .slick-next {
    right: 25px;
  }
}

html[data-theme=light] .stack__img img {
  filter: brightness(0);
}