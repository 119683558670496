// hero-banner
.hero-banner {
  padding: 0;

  &__wrapper {
    padding-top: 10rem;
    .button-wrapper {
      display: flex;
      justify-content: space-between;

      &__right {
        min-width: 8rem;
        text-align: right;
      }
    }
    &-imgbox {
      &__img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
/*Quote*/
.quote {
  padding-top: 10rem;

  &-wrapper {
    &__title {
      &-subhead {
        @include breakpoint(mobile-max) {
          color: var(--font-color);
        }
      }
    }
  }

  @include breakpoint(max-1680) {
    padding-top: 8.7rem;
  }
  @include breakpoint(max-1440) {
    padding-top: 7.5rem;
  }
  @include breakpoint(max-1366) {
    padding-top: 7.1rem;
  }
  @include breakpoint(max-1280) {
    padding-top: 6.6rem;
  }
  @include breakpoint(max-1024) {
    padding-top: 5.3rem;
  }
}

// grid

.grid {
  padding-bottom: 1rem;
  &__wrapper {
    column-count: 2;
    column-gap: 2rem;

    @include breakpoint(mobile-max) {
      column-count: 1;
    }
  }
  &__box {
    @include breakpoint(mobile-max) {
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &-video{
      height: 100%;
        width: 100%;
        object-fit: cover;
    }
  }
}
// Challenges
.challenges {
  &__title {
    text-align: center;

    @include breakpoint(mobile-max) {
      .heading-1 {
        font-size: 3.5rem;
      }
    }
    @include breakpoint(custom-small-mobile-max) {
      .heading-1 {
        font-size: 3rem;
      }
    }
  }
  &__wrapper {
    padding: 5rem 0;
    &__list {
      position: relative;
      &-item {
        display: flex;
        align-items: center;
        // flex-wrap: wrap;
        border: 0.05rem solid var(--card-border-color);
        position: relative;

        &__left,
        &__right {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 7rem 8rem;

          @include breakpoint(max-1680) {
            padding: 6.1rem 7rem;
          }
          @include breakpoint(max-1440) {
            padding: 5.2rem 6rem;
          }
          @include breakpoint(max-1366) {
            padding: 4.9rem 5.6rem;
          }
          @include breakpoint(max-1280) {
            padding: 4.6rem 5.3rem;
          }
          @include breakpoint(max-1024) {
            padding: 3.7rem 4.2rem;
          }
          @include breakpoint(mobile-max) {
            // padding: 2.8rem 3.2rem;
            padding: 2rem;
            // align-items: flex-start;
          }
        }
        &__left {
          .heading-1 {
            margin-right: 3rem;
            margin-bottom: 0;

            @include breakpoint(max-1680) {
              margin-right: 2.6rem;
            }
            @include breakpoint(max-1440) {
              margin-right: 2.2rem;
            }
            @include breakpoint(max-1366) {
              margin-right: 2.1rem;
            }
            @include breakpoint(max-1280) {
              margin-right: 2rem;
            }
            @include breakpoint(max-1024) {
              margin-right: 1.6rem;
            }
            @include breakpoint(mobile-max) {
              position: absolute;
              top: -1.2rem;
              left: 1.4rem;
              padding-left: 0.5rem;
              font-size: 2rem;
              background-color: var(--bg-color);
            }
          }
          @include breakpoint(mobile-max) {
            flex-direction: column;
            // padding-bottom: 0;
          }
        }
        &:after {
          content: "";
          position: absolute;
          height: 100%;
          border-left: 0.05rem solid var(--card-border-color);
          left: 50%;
          top: 50%;
          transform: translateY(-50%);
          bottom: 0;
        }

        &:nth-child(2) {
          border-top: none;
          border-bottom: none;
        }
        @include breakpoint(mobile-max) {
          align-items: flex-start;
        }
      }
    }
    @include breakpoint(max-1680) {
      padding: 4.3rem 0;
    }
    @include breakpoint(max-1440) {
      padding: 3.7rem 0;
    }
    @include breakpoint(max-1366) {
      padding: 3.5rem 0;
    }
    @include breakpoint(max-1280) {
      padding: 3.3rem 0;
    }
    @include breakpoint(max-1024) {
      padding: 2.6rem 0;
    }
  }
}

// stack

.stack {
  &__head {
    margin-bottom: 6rem;

    @include breakpoint(max-1680) {
      margin-bottom: 5.2rem;
    }
    @include breakpoint(max-1440) {
      margin-bottom: 4.5rem;
    }
    @include breakpoint(max-1366) {
      margin-bottom: 4.2rem;
    }
    @include breakpoint(max-1280) {
      margin-bottom: 4rem;
    }
    @include breakpoint(max-1024) {
      margin-bottom: 3.2rem;
    }
  }
  &__border {
    border-right: 0.5px solid #6f6e6e6e;

    &:last-child {
      border-right: 0;
    }
    @include breakpoint(mobile-max) {
      border-top: 0.5px solid #6f6e6e6e;
    }
  }
  &__title {
    min-height: 75px;
    margin-bottom: 2.5rem;
    border-bottom: 0.5px solid #6f6e6e6e;

    @include breakpoint(mobile-max) {
      min-height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    user-select: none;

    &-item {
      margin-bottom: 1.5rem;
      &:last-child {
        margin-bottom: 0;

        @include breakpoint(mobile-max) {
          margin-bottom: 3rem;
        }
      }
    }
  }
  &__img {
    display: inline-flex;
    align-items: center;
    background-color: var(--tabs-color);
    padding: 1.5rem 2rem;
    height: 10rem;
    width: 10rem;
  }

  @include breakpoint(mobile-max) {
    .row {
      [class*="col-"]:nth-child(even) {
        border-right: 0;
      }
    }
  }
}

// btm-grid
.btm-grid {
  padding-top: 1rem;
  &__wrapper {
    display: flex;
    gap: 2rem;

    @include breakpoint(mobile-max) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

// highlights
.highlights {
  &__title {
    // background-image: linear-gradient(
    //   -225deg,

    //   #ff1361 0%,
    //   #e60001 29%,
    //   #fa6f01 67%,
    //   #eb1c01 100%
    // );
    // background-size: auto auto;
    // background-clip: border-box;
    // background-size: 200% auto;
    // color: #fff;
    // background-clip: text;
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // animation: textclip 2s linear infinite;

    // @keyframes textclip {
    //   to {
    //     background-position: 200% center;
    //   }
    // }
    @include breakpoint(mobile-max) {
      text-align: center;
    }
  }
  &__list {
    > li + li {
      border-top: 0.1rem solid #4a4a4a;
    }
    counter-reset: num;
    position: relative;
    &-desc {
      position: relative;
      counter-increment: num;
      padding-left: 5rem;
      padding-top: 4rem;
      padding-bottom: 4rem;
      &:before {
        content: "0" counter(num) ".";
        position: absolute;
        left: 0;
        top: 4.2rem;
        color: $num-grey;
        font-weight: 900;
        font-size: 2.8rem;
        @include breakpoint(max-1680) {
          font-size: 2.4rem;
          top: 3.6rem;
        }
        @include breakpoint(max-1440) {
          font-size: 2.1rem;
          top: 3.2rem;
        }
        @include breakpoint(max-1366) {
          font-size: 1.9rem;
          top: 3rem;
        }
        @include breakpoint(max-1280) {
          font-size: 1.8rem;
          top: 2.7rem;
        }
        @include breakpoint(max-1024) {
          font-size: 1.5rem;
          top: 2.3rem;
        }
        @include breakpoint(mobile-max) {
          left: 2rem;
          top: 2.2rem;
        }
      }

      @include breakpoint(max-1680) {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
      }
      @include breakpoint(max-1440) {
        padding-top: 3rem;
        padding-bottom: 3rem;
      }
      @include breakpoint(max-1366) {
        padding-top: 2.8rem;
        padding-bottom: 2.8rem;
      }
      @include breakpoint(max-1280) {
        padding-top: 2.6rem;
        padding-bottom: 2.6rem;
      }
      @include breakpoint(max-1024) {
        padding-top: 2.1rem;
        padding-bottom: 2.1rem;
      }

      .list-head {
        margin: 0;
        color: var(--font-color);
      }

      .sublist {
        padding-left: 3rem;
        margin-top: 2.8rem;

        &__item {
          display: list-item;
          list-style-type: disc;
          padding: 0.5rem 0;
        }
        @include breakpoint(max-1680) {
          margin-top: 2.4rem;
        }
        @include breakpoint(max-1440) {
          margin-top: 2.1rem;
        }
        @include breakpoint(max-1366) {
          margin-top: 1.9rem;
        }
        @include breakpoint(max-1280) {
          margin-top: 1.8rem;
        }
        @include breakpoint(max-1024) {
          margin-top: 1.5rem;
        }
        @include breakpoint(mobile-max) {
          padding-left: 2rem;
        }
      }
    }
  }
}

// projects
.project {
  overflow: hidden;

  .swiper-slide {
    transition: transform 0.3s ease 0s;
    opacity: 0.5;

    @include breakpoint(mobile-max) {
      opacity: 0.7;
    }
  }
  .swiper-slide-active {
    transform: translateY(-50px);
    opacity: 1;

    @include breakpoint(mobile-max) {
      transform: translateY(-20px);
    }
  }

  @include breakpoint(mobile-max) {
    padding-top: 6rem;
    #swip-cursor {
      display: none;
    }
    .swiper-container {
      padding: 0 2rem;
    }
  }
  &-slider{
    &__details{
      border: 1px solid #afafaf;
    }
  }
}

// upcoming__slider

.upcoming {
  &__slider {
    &__wrapper {
      background: var(--tabs-color);
      padding: 3rem 4rem;
      .slider-row {
        align-items: center;
        justify-content: center;

        @include breakpoint(mobile-max) {
          flex-direction: column-reverse;
        }
      }
      .slider-col {
        margin-top: 2rem;
      }

      .slider-link {
        transition: 0.5s ease;
        text-decoration: none !important;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
    .slick-next,
    .slick-prev {
      position: absolute;
      display: block;
      line-height: 0;
      font-size: 0;
      font-family: "#{$icomoon-font-family}" !important;
      cursor: pointer;
      background: 0 0;
      color: transparent;
      top: 50%;
      transform: translate(0, -50%);
      padding: 0;
      border: 0;
      outline: 0;
      z-index: 5;
    }
    .slick-prev {
      left: -30px;
      &:before {
        content: "\e901";
        position: absolute;
        font-size: 35px;
        color: var(--font-color);
      }
      @include breakpoint(mobile-max) {
        left: 12px;
      }
    }
    .slick-next {
      right: -20px;
      &:before {
        content: "";
        content: "\e900";
        position: absolute;
        font-size: 35px;
        color: var(--font-color);
      }
      @include breakpoint(mobile-max) {
        right: 25px;
      }
    }
  }
}

html[data-theme="light"] {
  .stack {
    &__img {
      img {
        filter: brightness(0);
      }
    }
  }
}
