.footer{
    padding: 2rem 0rem;
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 300;
    background-color:var(--tabs-color);
    letter-spacing: 0.5px;

    &__desc{
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include breakpoint(mobile-max){
            flex-wrap: wrap;
            justify-content: center;
        }

        &-left,&-right{
            margin-top: 0;
            margin-bottom: 0;
        }
        &-left{
            @include breakpoint(mobile-max){
                margin-bottom: 1rem;
            }
        }
    }
    @include breakpoint(mobile-max){
        text-align: center;
    }
}