// header
.header {
  top: 0;
  z-index: 100;
  height: 6.4rem;
  backdrop-filter: blur(1.5rem);
  background-color: var(--header-bg);
  &-logo {
    width: 15rem;

    @include breakpoint(mobile-max) {
      margin-left: 3rem;
    }
    @include breakpoint(custom-small-mobile-max){
      margin-left: 2rem;
    }
  }
  .navbar {
    padding: 1rem 0;

    &-brand {
      z-index: 1;
      @include breakpoint(ipad-max) {
        order: 2;
      }
      @include breakpoint(mobile-max){
        margin-right: 0;
      }
    }
    &-nav {
      .nav-item {
        margin: 0 2rem 0 0;
        .nav-link {
          color: $white;
          font-size: 1.43rem;
          background: linear-gradient( to right, $red, $red 50%, var(--font-color) 50% );
          background-size: 200% 100%;
          background-position: -100%;
          display: inline-block;
          position: relative;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          transition: all 0.4s ease-in-out;
          font-weight: 300;

          &:before {
            content: '';
            background: $red;
            display: block;
            position: absolute;
            bottom: -0.3rem;
            left: 0;
            width: 0;
            height: 0.2rem;
            transition: all 0.4s ease-in-out;
          }
          &:hover {
            background-position: 0;
            &::before {
              width: 100%;
            }
          }
        }
       
        @include breakpoint(ipad-max) {
          margin-right: 0;
          padding: 1.2rem;
          border-bottom: 0.05rem solid var(--border-color);

          &:first-child {
            padding-top: 0;
          }
        }
      }
      @include breakpoint(ipad-max) {
        padding: 2rem 2.3rem;
      }
      @include breakpoint(mobile-max) {
        padding: 2rem 1rem;
      }
    }
    &_dl-switch {
      /* Rounded sliders */
      .switch {
        position: relative;
        display: inline-block;
        width: 6rem;
        height: 3.4rem;
        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $light-grey;
        transition: 0.4s;
        &:before {
          position: absolute;
          content: "";
          height: 2.6rem;
          width: 2.6rem;
          left: 0.4rem;
          bottom: 0.4rem;
          background: url("../images/light-switch.svg") no-repeat center center;
          background-size: cover;
          transition: 0.4s;
          box-shadow: 0rem 0.4rem 0.3rem 0rem rgba(0, 0, 0, 0.55);
        }
      }
      input {
        &:checked {
          + {
            .slider {
              background-color: $dark-grey;

              &:before {
                transform: translateX(2.6rem);
                background: url("../images/dark-switch.svg") no-repeat center
                  center;
                background-size: cover;
                box-shadow: 0rem 0.4rem 0.3rem 0rem rgba(0, 0, 0, 0.55);
              }
            }
          }
        }
        &:focus {
          + {
            .slider {
              box-shadow: 0 0 0.1rem #1b1b1b;
            }
          }
        }
      }
      .slider.round {
        border-radius: 3.4rem;
        &:before {
          border-radius: 50%;
        }
      }
      @include breakpoint(ipad-max) {
        order: 3;
      }
    }
    &-toggler {
      width: 20px;
      height: 20px;
      position: relative;
      transition: 0.5s ease-in-out;
      outline: none;
      box-shadow: none;
      border: none;
      position: relative;
      &:focus {
        outline: none;
        box-shadow: none;
        border: none;
        position: relative;
      }
      &:active {
        outline: none;
        box-shadow: none;
        border: none;
        position: relative;
      }
      span {
        margin: 0;
        padding: 0;
      }
      .top-bar {
        top: inherit;
        transform: rotate(135deg);
      }
      .middle-bar {
        opacity: 0;
        top: inherit;
      }
      .bottom-bar {
        top: inherit;
        transform: rotate(-135deg);
      }
      &.collapsed {
        .top-bar {
          position: absolute;
          top: 0;
          transform: rotate(0deg);
        }
        .middle-bar {
          opacity: 1;
          position: absolute;
          top: 10px;
        }
        .bottom-bar {
          position: absolute;
          top: 20px;
          transform: rotate(0deg);
        }
      }
    }
   
    &-collapse {
      @include breakpoint(ipad-max){
        position: fixed;
      top: 0;
      padding-top: 6.4rem;
      left: -100vw;;
      width: 100vw;
      height: 100vh;
      background: var(--bg-color);
      display: block !important;
      transition: 0.3s ease-in;
      }
    }
     .show { 
      left: 0;

    }
    @include breakpoint(ipad-max){
      padding: 1.5rem 1rem;
    }
  }

  .toggler-icon {
    display: block;
    position: absolute;
    height: 0.26rem;
    width: 2.4rem;
    background: var( --toggler-color);
    border-radius: 0.1rem;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.3s ease-in;
    z-index: 1;
  }
  .middle-bar {
    margin-top: 0;
  }
}
html[data-theme="dark"]{
  .header{
    &-logo{
      filter: brightness(0) invert(1);
    }
  }
}