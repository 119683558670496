.heading-1{
    font-size: 8.2rem;
    letter-spacing: 1px;
    font-weight: 300;

    @include breakpoint(max-1680){
        font-size: 7.1rem;
    }
    @include breakpoint(max-1440){
        font-size: 6.1rem;
    }
    @include breakpoint(max-1366){
        font-size: 5.7rem;
    }
    @include breakpoint(max-1280){
        font-size: 5.3rem;
    }
    @include breakpoint(max-1024){
        font-size: 4.2rem;
    }
    @include breakpoint(mobile-max){
        letter-spacing: 0.5px;
    }
}
.heading-2{
    font-size:7.7rem;
    line-height: 10rem;
    letter-spacing: 1px;
    font-weight: 300;

    @include breakpoint(max-1680){
        font-size: 6.7rem;
        line-height: 8.8rem;
    }
    @include breakpoint(max-1440){
        font-size: 5.7rem;
        line-height: 7.5rem
    }
    @include breakpoint(max-1366){
        font-size: 5.3rem;
        line-height: 7.1rem;
    }
    @include breakpoint(max-1280){
        font-size: 5rem;
        line-height: 6.6rem;
    }
    @include breakpoint(max-1024){
        font-size: 4.2rem;
        line-height: 5.3rem;
    }
    @include breakpoint(mobile-max){
        letter-spacing: 0.5px;
    }
    @include breakpoint(portrait-mobile-max){
        font-size: 3.5rem;
        line-height: 4.9rem;
    }
    @include breakpoint(small-mobile-max){
        font-size: 3.1rem;
        line-height: 4.5rem;
    }
    @include breakpoint(custom-small-mobile-max){
        font-size: 2.7rem;
        line-height: 4rem;
    }
   
}
.heading-3{

    font-size: 5.9rem;
    letter-spacing: 1px;
    font-weight: 300;

    @include breakpoint(max-1680){
        font-size: 5.1rem;
    }
    @include breakpoint(max-1440){
        font-size: 4.4rem;
    }
    @include breakpoint(max-1366){
        font-size: 4.1rem;
    }
    @include breakpoint(max-1280){
        font-size: 3.9rem;
    }
    @include breakpoint(max-1024){
        font-size: 3.2rem;
    }
    @include breakpoint(mobile-max){
        letter-spacing: 0.5px;
    }
    @include breakpoint(small-mobile-max){
        font-size: 2.7rem;
    }

}
.heading-4{
    font-size: 3.4rem;
    letter-spacing: 1px;
    font-weight: 300;

    @include breakpoint(max-1680){
        font-size: 2.9rem;
    }
    @include breakpoint(max-1440){
        font-size: 2.5rem;
    }
    @include breakpoint(max-1366){
        font-size: 2.4rem;
    }
    @include breakpoint(max-1280){
        font-size: 2.2rem;
    }
    @include breakpoint(max-1024){
        font-size: 1.7rem;
    }
    @include breakpoint(mobile-max){
        letter-spacing: 0.5px;
    }
}
.desc-1{
    font-size: 2.7rem;
    letter-spacing: 1px;
    font-weight: 300;

    @include breakpoint(max-1680){
        font-size: 2.3rem;
    }
    @include breakpoint(max-1440){
        font-size: 2rem;
    }
    @include breakpoint(max-1366){
        font-size: 1.8rem;
    }
    @include breakpoint(max-1280){
        font-size: 1.7rem;
    }
    @include breakpoint(max-1024){
        font-size: 1.4rem;
    }
    @include breakpoint(mobile-max){
    color: $white;
    letter-spacing: 0.5px;
    }
}
.desc-2{
    font-size: 2.3rem;
    line-height: 2.8rem;
    letter-spacing: 1px;
    font-weight: 300;
    
    @include breakpoint(max-1680){
        font-size: 2rem;
        line-height: 2.4rem;
    }
    @include breakpoint(max-1440){
        font-size: 1.7rem;
        line-height: 2.1rem;
    }
    @include breakpoint(max-1366){
        font-size: 1.6rem;
        line-height: 1.9rem;
    }
    @include breakpoint(max-1280){
        font-size: 1.5rem;
        line-height: 1.8rem;
    }
    @include breakpoint(max-1024){
        font-size: 1.1rem;
        line-height: 1.4rem;
    }
    @include breakpoint(mobile-max){
        letter-spacing: 0.5px;
    }

}
.desc-3{
    font-size: 1.9rem;
    line-height: 3rem;
    letter-spacing: 1px;
    font-weight: 300;

    @include breakpoint(max-1680){
        font-size: 1.6rem;
        line-height: 2.6rem;
    }
    @include breakpoint(max-1440){
        font-size: 1.4rem;
        line-height: 2.2rem;
    }
    @include breakpoint(max-1366){
        font-size: 1.3rem;
        line-height: 2.1rem;
    }
    @include breakpoint(max-1280){
        font-size: 1.2rem;
        line-height: 2rem;
    }
    @include breakpoint(max-1024){
        font-size: 0.9rem;
        line-height: 1.6rem;
    }
    @include breakpoint(mobile-max){
        letter-spacing: 0.5px;
    }
}