section {
  padding: 10rem 0;

  @include breakpoint(max-1680) {
    padding: 8.8rem 0;
  }
  @include breakpoint(max-1440) {
    padding: 7.5rem 0;
  }
  @include breakpoint(max-1366) {
    padding: 7.1rem 0;
  }
  @include breakpoint(max-1280) {
    padding: 6.6rem 0;
  }
  @include breakpoint(max-1024) {
    padding: 5.3rem 0;
  }
  @include breakpoint(mobile-max) {
    padding: 2rem 0;
  }
}
// contact-us
.contact-us {
  background: url("../images/contact-bg.jpg") no-repeat center center;
  background-size: cover;
  margin-top: 10rem;

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10rem 0;

    &-title {
      color: $white !important;
      margin-bottom: 3rem;

      @include breakpoint(max-1680) {
        margin-bottom: 2.6rem;
      }
      @include breakpoint(max-1440) {
        margin-bottom: 2.2rem;
      }
      @include breakpoint(max-1366) {
        margin-bottom: 2.1rem;
      }
      @include breakpoint(max-1280) {
        margin-bottom: 2rem;
      }
      @include breakpoint(max-1024) {
        margin-bottom: 1.6rem;
      }
    }

    @include breakpoint(max-1680) {
      padding: 8.8rem 0;
    }
    @include breakpoint(max-1440) {
      padding: 7.5rem 0;
    }
    @include breakpoint(max-1366) {
      padding: 7.1rem 0;
    }
    @include breakpoint(max-1280) {
      padding: 6.6rem 0;
    }
    @include breakpoint(max-1024) {
      padding: 5.3rem 0;
    }
    @include breakpoint(mobile-max) {
      padding: 2rem 0;
    }
  }

  @include breakpoint(max-1680) {
    margin-top: 8.8rem;
  }
  @include breakpoint(max-1440) {
    margin-top: 7.5rem;
  }
  @include breakpoint(max-1366) {
    margin-top: 7.1rem;
  }
  @include breakpoint(max-1280) {
    margin-top: 6.6rem;
  }
  @include breakpoint(max-1024) {
    margin-top: 5.3rem;
  }
  @include breakpoint(mobile-max) {
    margin-top: 2rem;
  }
}
.contact-index {
  margin-top: 0;
}

.stroke-text {
  color: $stroke-text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $stroke-text;
}

#custom-cursor {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  pointer-events: none;
}
@media (hover: hover) and (pointer: fine) {
  .cursor__circle {
    width: 1rem;
    height: 1rem;
    margin-top: -50%;
    margin-left: -50%;
    border-radius: 50%;
    background-color: $black;
    border: solid 1px rgba(227, 222, 193, 0.64);
    transition: opacity 0.9s cubic-bezier(0.25, 1, 0.5, 1),
      background-color 0.3s cubic-bezier(1, 1, 1, 1),
      border-color 0.3s cubic-bezier(0.25, 1, 0.5, 1),
      width 0.3s cubic-bezier(0.25, 1, 0.5, 1),
      height 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  }
  #custom-cursor {
    &::after {
      content: "View Project";
      position: absolute;
      left: -50%;
      top: -50%;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 0;
      transition: 0.3s all;
    }
    &.arrow {
      &::after {
        font-size: 1.2rem;
      }
      .cursor__circle {
        width: 10rem;
        height: 10rem;
        border-radius: 100%;
        background-color: $black;
        border-color: $black;
      }
    }
  }
}

#swip-cursor {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  pointer-events: none;
}
@media (hover: hover) and (pointer: fine) {
  .swip__circle {
    width: 1rem;
    height: 1rem;
    margin-top: -50%;
    margin-left: -50%;
    border-radius: 50%;
    background-color: $black;
    border: solid 1px rgba(227, 222, 193, 0.64);
    transition: opacity 0.9s cubic-bezier(0.25, 1, 0.5, 1),
      background-color 0.3s cubic-bezier(1, 1, 1, 1),
      border-color 0.3s cubic-bezier(0.25, 1, 0.5, 1),
      width 0.3s cubic-bezier(0.25, 1, 0.5, 1),
      height 0.3s cubic-bezier(0.25, 1, 0.5, 1);
  }
  #swip-cursor {
    &::after {
      content: "";
      position: absolute;
      left: -50%;
      top: -50%;
      width: 100%;
      height: 100%;
      background-image: url("../images/drag.svg");
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-size: 0;
      transition: 0.3s all;
    }
    &.arrow {
      &::after {
        font-size: 2rem;
      }
      .swip__circle {
        width: 10rem;
        height: 10rem;
        border-radius: 100%;
        background-color: $black;
        border-color: $black;
      }
    }
  }
}
.tooltip {
  font-size: 1.2rem;
}
