.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: var(--loader-color);
  .loader {
    border: 0.8rem solid $red;
    border-radius: 50%;
    border-top: 0.8rem solid $light-grey;
    width: 6rem;
    height: 6rem;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
