// @font-face {
//     font-family: "Poppins";
//     src: url("#{$fonts-path}/Poppins-Thin.ttf") format("truetype"),
//       url("#{$fonts-path}/Poppins-Thin.ttf") format("truetype");
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
//   }
  
//   @font-face {
//     font-family: "Poppins";
//     src: url("#{$fonts-path}/Poppins-ExtraLight.ttf") format("truetype"),
//       url("#{$fonts-path}/Poppins-ExtraLight.ttf") format("truetype");
//     font-weight: 200;
//     font-style: normal;
//     font-display: swap;
//   }
  
  @font-face {
    font-family: "Poppins";
    src: url("#{$fonts-path}/Poppins-Light.ttf") format("truetype"),
      url("#{$fonts-path}/Poppins-Light.ttf") format("truetype")
      url("#{$fonts-path}/Poppins-Light.woff2") format("woff2"),
    url("#{$fonts-path}/Poppins-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Poppins";
    src: url("#{$fonts-path}/Poppins-Regular.ttf") format("truetype"),
      url("#{$fonts-path}/Poppins-Regular.ttf") format("truetype"),
      url("#{$fonts-path}/Poppins-Regular.woff2") format("woff2"),
      url("#{$fonts-path}/Poppins-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Poppins";
    src: url("#{$fonts-path}/Poppins-Medium.ttf") format("truetype"),
      url("#{$fonts-path}/Poppins-Medium.ttf") format("truetype")
      url("#{$fonts-path}/Poppins-Medium.woff2") format("woff2"),
      url("#{$fonts-path}/Poppins-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  // @font-face {
  //   font-family: "Poppins";
  //   src: url("#{$fonts-path}/Poppins-MediumItalic.ttf") format("truetype"),
  //     url("#{$fonts-path}/Poppins-MediumItalic.ttf") format("truetype");
  //   font-weight: 500;
  //   font-style: italic;
  //   font-display: swap;
  // }
  
  @font-face {
    font-family: "Poppins";
    src: url("#{$fonts-path}/Poppins-SemiBold.ttf") format("truetype"),
      url("#{$fonts-path}/Poppins-SemiBold.ttf") format("truetype")
      url("#{$fonts-path}/Poppins-SemiBold.woff2") format("woff2"),
      url("#{$fonts-path}/Poppins-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Poppins";
    src: url("#{$fonts-path}/Poppins-Bold.ttf") format("truetype"),
      url("#{$fonts-path}/Poppins-Bold.ttf") format("truetype")
      url("#{$fonts-path}/fonts/Poppins-Bold.woff2") format("woff2"),
      url("#{$fonts-path}/fonts/Poppins-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  // @font-face {
  //   font-family: "Poppins";
  //   src: url("#{$fonts-path}/Poppins-ExtraBold.ttf") format("truetype"),
  //     url("#{$fonts-path}/Poppins-ExtraBold.ttf") format("truetype");
  //   font-weight: 800;
  //   font-style: normal;
  //   font-display: swap;
  // }
  
  // @font-face {
  //   font-family: "Poppins";
  //   src: url("#{$fonts-path}/Poppins-Black.ttf") format("truetype"),
  //     url("#{$fonts-path}/Poppins-Black.ttf") format("truetype");
  //   font-weight: 900;
  //   font-style: normal;
  //   font-display: swap;
  // }