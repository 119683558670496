// Section Digital
.digital {
  padding: 0;

  &__title {
    padding: 10rem 0;
    @include breakpoint(max-1680) {
      padding: 8.7rem 0;
    }
    @include breakpoint(max-1440) {
      padding: 7.5rem 0;
    }
    @include breakpoint(max-1366) {
      padding: 7.1rem 0;
    }
    @include breakpoint(max-1280) {
      padding: 6.6rem 0;
    }
    @include breakpoint(max-1024) {
      padding: 5.3rem 0;
    }
  }

  &-wrapper {
    height: 100%;
    position: relative;

    &__video-box {
      height: calc(100vh - 60px);
      pointer-events: none;
      .desk-video{
        display:block
      }
      .mob-video{
        display: none;
      }

      &__video {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      @include breakpoint(mobile-max){
        .desk-video{
          display: none;
        }
        .mob-video{
          display:block
        }
      }
    }

    &__contents {
      display: flex;
      flex-direction: column;
      &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding-top: 10rem;
        .list-items {
          padding: 5% 3%;
          width: 25%;
          position: relative;

          &:after {
            content: "";
            position: absolute;
            background-color: red;
            height: 1px;
            width: 50%;
            bottom: 15%;
            left: 50%;
            transform: translateX(-50%);
          }

          &__title {
            margin-top: 2rem;
            margin-bottom: 0;

            @include breakpoint(max-1680) {
              margin-top: 1.7rem;
            }
            @include breakpoint(max-1440) {
              margin-top: 1.5rem;
            }
            @include breakpoint(max-1366) {
              margin-top: 1.4rem;
            }
            @include breakpoint(max-1280) {
              margin-top: 1.3rem;
            }
            @include breakpoint(max-1024) {
              margin-top: 1rem;
            }
          }
          &__numbers {
            margin-bottom: 0;
            // font-family: $primary-font-semibold;
          }

          @include breakpoint(mobile-max) {
            width: 100%;
            padding: 3rem 0;
          }
        }
        @include breakpoint(max-1680) {
          padding-top: 8.7rem;
        }
        @include breakpoint(max-1440) {
          padding-top: 7.5rem;
        }
        @include breakpoint(max-1366) {
          padding-top: 7.1rem;
        }
        @include breakpoint(max-1280) {
          padding-top: 6.6rem;
        }
        @include breakpoint(max-1024) {
          padding-top: 5.3rem;
        }
        @include breakpoint(mobile-max) {
          text-align: center;
          padding: 1rem;
        }
      }
    }
  }
}

// our work
.our-work {
  padding-top: 10rem;
  padding-bottom: 0;
  &__tabs {
    padding: 5rem 0;
    .main-tabs {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-end;
      overflow: auto;
      flex-wrap: nowrap;
      border-bottom: 0;

      .nav-item {
        .nav-link {
          margin-bottom: 0px;
          border: none;
          border-radius: 0;
          padding: 2rem 4rem;
          cursor: pointer;
          border-bottom: 0.1rem solid transparent;
          transition: all 0.5s ease;
          font-weight: 300;
          span {
            text-align: center;
            width: 3rem;
            position: relative;
            display: inline-block;
            left: 6rem;
            opacity: 0;
            transition: all 0.3s ease 0s;

            .icon-php {
              display: inline-flex;
              font-size: 4rem;
              transform: translateY(3px);

              @include breakpoint(max-1680) {
                transform: translateY(5px);
              }
              @include breakpoint(max-1440) {
                transform: translateY(7px);
              }
              @include breakpoint(max-1280) {
                transform: translateY(6px);
                font-size: 3.5rem;
              }
              @include breakpoint(max-1024) {
                font-size: 3rem;
              }
            }
          }

          &.active,
          &:hover {
            span {
              left: -2rem;
              opacity: 1;

              @include breakpoint(max-1024) {
                left: -1rem;
              }
            }
          }

          // @include breakpoint(max-1680) {
          //   padding: 1.7rem 3.5rem;
          // }
          // @include breakpoint(max-1440) {
          //   padding: 1.5rem 3rem;
          // }
          // @include breakpoint(max-1366) {
          //   padding: 1.4rem 2.8rem;
          // }
          // @include breakpoint(max-1280) {
          //   padding: 1.3rem 2.6rem;
          // }
          // @include breakpoint(max-1024) {
          //   padding: 1rem 2.1rem;
          // }
        }

        .active {
          border-bottom: 0.1rem solid $red;
          background-color: transparent;
        }
        @include breakpoint(mobile-max) {
          // margin-right: 3rem;
          // min-width: 60vw;
          margin-right: 2rem;
          min-width: 50vw;
          text-align: center;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      @include breakpoint(mobile-max) {
        white-space: nowrap;
        justify-content: flex-start;

        &::-webkit-scrollbar {
          border: none;
        }
      }
    }

    .inner-main-wrapper {
      padding: 5rem 0;

      .row {
        &:hover {
          .inner-contents-box__subhead-1 {
            &::after {
              width: 100%;
            }
          }
        }
        @include breakpoint(mobile-max) {
          .inner-contents-box__subhead-1 {
            &::after {
              width: 100% !important;
            }
          }
        }
      }

      .hide {
        display: none;
      }
      .inner-border {
        padding: 10rem;
        border: 0.1rem solid var(--border-color);

        .inner-contents {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 80%;
          margin-top: 5rem;

          &-box {
            &__subhead-1 {
              display: inline-block;
              margin-bottom: 1.4rem;
              line-height: 3rem;

              &:after {
                content: "";
                display: block;
                width: 0%;
                height: 3px;
                background: $red;
                transition: width 0.3s;
              }
              // &:hover::after {
              //     width: 100%;
              // }

              @include breakpoint(max-1680) {
                margin-bottom: 1.2rem;
              }
              @include breakpoint(max-1440) {
                margin-bottom: 1rem;
              }
              @include breakpoint(max-1366) {
                margin-bottom: 0.9rem;
              }
              @include breakpoint(max-1280) {
                margin-bottom: 0.9rem;
              }
              @include breakpoint(max-1024) {
                margin-bottom: 0.7rem;
              }
            }
            &__title {
              margin-bottom: 3rem;

              @include breakpoint(max-1680) {
                margin-bottom: 2.6rem;
              }
              @include breakpoint(max-1440) {
                margin-bottom: 2.2rem;
              }
              @include breakpoint(max-1366) {
                margin-bottom: 2.1rem;
              }
              @include breakpoint(max-1280) {
                margin-bottom: 2rem;
              }
              @include breakpoint(max-1024) {
                margin-bottom: 1.6rem;
              }
            }
            &__subhead-2 {
              display: flex;
              align-items: center;
              @include breakpoint(mobile-max) {
                justify-content: center;
                margin-bottom: 1.5rem;
              }
              i {
                border: 0.05rem solid var(--border-color);
                border-radius: 0.5rem;
                margin-left: 0.5rem;
                padding: 0.5rem;
              }
            }
          }

          &__video-box {
            &__video {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          @include breakpoint(max-1680) {
            margin-top: 4.3rem;
          }
          @include breakpoint(max-1440) {
            margin-top: 3.7rem;
          }
          @include breakpoint(max-1366) {
            margin-top: 3.5rem;
          }
          @include breakpoint(max-1280) {
            margin-top: 3.3rem;
          }
          @include breakpoint(max-1024) {
            margin-top: 2.6rem;
          }
          @include breakpoint(mobile-max) {
            height: 100%;
            text-align: center;
          }
        }

        &:nth-child(odd) {
          border-right: 0;

          @include breakpoint(mobile-max) {
            border-right: 0.1rem solid var(--border-color);
            border-bottom: 0.1rem solid var(--border-color);
            border-top: 0;
            padding-top: 0;
          }
        }

        @include breakpoint(max-1680) {
          padding: 8.7rem;
        }
        @include breakpoint(max-1440) {
          padding: 7.5rem;
        }
        @include breakpoint(max-1366) {
          padding: 7.1rem;
        }
        @include breakpoint(max-1280) {
          padding: 6.6rem;
        }
        @include breakpoint(max-1024) {
          padding: 5.3rem;
        }
        @include breakpoint(mobile-max) {
          padding: 3.9rem;
          &:nth-child(even) {
            border-bottom: 0;
            padding-bottom: 0;
          }
        }
      }

      @include breakpoint(max-1680) {
        padding: 4.3rem 0;
      }
      @include breakpoint(max-1440) {
        padding: 3.7rem 0;
      }
      @include breakpoint(max-1366) {
        padding: 3.5rem 0;
      }
      @include breakpoint(max-1280) {
        padding: 3.3rem 0;
      }
      @include breakpoint(max-1024) {
        padding: 2.6rem 0;
      }
      @include breakpoint(mobile-max) {
        .row {
          flex-direction: column-reverse;
        }
      }
    }
    .row:nth-child(even) {
      .inner-border {
        border-top: none;
        border-bottom: none;
      }
      &:last-child{
        border-bottom: 0.1rem solid var(--border-color);
      }
    }
    // .row:last-child {
    //   .inner-border {
    //     border-bottom: 0.1rem solid var(--border-color);
    //   }
    // }
    @include breakpoint(max-1680) {
      padding: 4.3rem 0;
    }
    @include breakpoint(max-1440) {
      padding: 3.7rem 0;
    }
    @include breakpoint(max-1366) {
      padding: 3.5rem 0;
    }
    @include breakpoint(max-1280) {
      padding: 3.3rem 0;
    }
    @include breakpoint(max-1024) {
      padding: 2.6rem 0;
    }
  }
  &__grp {
    padding: 5rem 0;
    .hide {
      display: none !important;
    }
    @include breakpoint(max-1680) {
      padding: 4.3rem 0;
    }
    @include breakpoint(max-1440) {
      padding: 3.7rem 0;
    }
    @include breakpoint(max-1366) {
      padding: 3.5rem 0;
    }
    @include breakpoint(max-1280) {
      padding: 3.3rem 0;
    }
    @include breakpoint(max-1024) {
      padding: 2.6rem 0;
    }
  }
  @include breakpoint(max-1680) {
    padding-top: 8.7rem;
  }
  @include breakpoint(max-1440) {
    padding-top: 7.5rem;
  }
  @include breakpoint(max-1366) {
    padding-top: 7.1rem;
  }
  @include breakpoint(max-1280) {
    padding-top: 6.6rem;
  }
  @include breakpoint(max-1024) {
    padding-top: 5.3rem;
  }
  @include breakpoint(mobile-max) {
    #custom-cursor {
      display: none;
    }
  }
}
