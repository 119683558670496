html {
  font-size: 10px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body {
  font-size: 1rem;
  font-family: $primary-font-regular;
  background-color: var(--bg-color);
  position: relative;
  color: var(--font-color);
  transition: 0.3s all;
}
/**
* Firefox specific rule
*/

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a {
  display: inline-block !important;
  color: inherit !important;
  text-decoration: none !important;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
}

li {
  list-style-type: none;
  display: inline-block;
}

img {
  margin: 0;
  max-width: 100%;
  height: auto;
  border: 0;
  display: inline-block;
}
button {
  outline: none;
  border: none;
}



// // width 
// ::-webkit-scrollbar {
// 	height: 5px;  
// 	width: 5px; 
// 	border: 1px solid transparent;
// }

// //  Track 
// ::-webkit-scrollbar-track {
// 	background: transparent;
// }

// // Handle 
// ::-webkit-scrollbar-thumb {
// 	background: $red;
// }

// // Handle  on hover 
// ::-webkit-scrollbar-thumb:hover {
// 	background: #555;
// }
