// Fonts
$primary-font-regular: 'Poppins';
$primary-font-light: 'Poppins';
$primary-font-medium: 'Poppins';
$primary-font-semibold: 'Poppins';
$primary-font-bold: 'Poppins';

// tooltip
$tooltip-font-size:2rem;

// color
$black:#000000;
$white:#ffffff;

// grey
$light-grey:#D9D9D9;
$num-grey:#797979;
$dark-grey:#1B1B1B;


$stroke-text:#919191;

// red
$red:#EB0C0C;

$fonts-path: "../fonts" !default;

:root {
    --bg-color: #ffffff;
    --border-color:rgba(129, 129, 129, 1);
    --card-border-color:rgba(0,0,0,0.55);
    --font-color: #000000;
    --color-red: #EB0C0C;
    --color-light-grey:#D9D9D9;
    --color-dark-grey:#1B1B1B;
    --header-bg:rgba(255,255,255,0.65);
    --toggler-color:#000000;
    --tabs-color:#D9D9D9;
    --loader-color:rgba(255,255,255,0.9);
    --arrow-right-color:#ffffff;
}
[data-theme="dark"]{
    --bg-color: #000000;
    --border-color:rgba(255,255,255,0.5);
    --card-border-color:rgba(85, 85, 85, 1);
    --font-color: #ffffff;
    --color-red: #EB0C0C;
    --color-light-grey:#D9D9D9;
    --color-dark-grey:#1B1B1B;
    --header-bg:rgba(0, 0, 0, 0.55);
    --toggler-color:#ffffff;
    --tabs-color:#161616;
    --loader-color:rgba(0,0,0, 0.7);
    --arrow-right-color:#000000;
}


// iconmoons
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

$icon-drupal: "\e902";
$icon-magento: "\e903";
$icon-php: "\e904";
$icon-arrow-right: "\e909";
$icon-angle-right: "\e900";
$icon-angle-left: "\e901";
$icon-wordpress: "\eab4";