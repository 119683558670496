/*!
* Project Name
* Architecture for a Sass Project
* https://www.sitepoint.com/architecture-sass-project/
* OR
* http://matthewelsom.com/blog/simple-scss-playbook.html
*/

@charset 'utf-8';


//Helpers
@import "helpers/__helpers-dir";

//Vendor
@import "vendors/__vendor-dir";

//Base Styles
@import "base/__base-dir";

//Components
@import "components/__components-dir";

//Layout
@import "layout/__layout-dir";

//Pages
@import "pages/__pages-dir";